* {
  margin: 0;
  padding: 0;
}
a {
  color: #ffffff;
  text-decoration: none;
}
.pg-bawah {
    font-family: "Montserrat", sans-serif;
}

.bawah {
  background: #054599;
  /* margin-top: 50px; */
}
.bawah-wave-svg {
  background-color: transparent;
  display: block;
  height: 30px;
  position: relative;
  top: -1px;
  width: 100%;
}
.bawah-wave-path {
  fill: #0c2569;
}

.bawah-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1230px;
  padding: 40px 15px 350px !important;
  position: relative;
}

.bawah-content-column {
  box-sizing: border-box;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  color: #9c9c9c;
}

.logo-bawah {
  width: 80%;
}

.bawah-content-column ul li a {
  color: #9c9c9c;
  text-decoration: none;
}

.bawah-logo-link {
  display: inline-block;
}
.bawah-menu {
  margin-top: 30px;
}

.bawah-menu-name {
  color: #ffffff;
  font-size: 15px;
  /* font-weight: 900; */
  letter-spacing: 0.1em;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}
.bawah-menu-list {
  list-style: none;
  margin-bottom: 0;
  margin-top: 10px;
  padding-left: 0;
}
.bawah-menu-list li {
  margin-top: 5px;
}

.bawah-call-to-action-description {
  color: rgb(255, 255, 255);
  margin-top: 10px;
  margin-bottom: 20px;
}
/* .bawah-call-to-action-button:hover {
        background-color: #ffffff;
        color: #007a3f;
    } */
.button:last-of-type {
  margin-right: 0;
}
.bawah-call-to-action-button {
  background-color: #00357a;
  border-radius: 21px;
  color: #ffffff;
  display: inline-block;
  font-size: 11px;
  /* font-weight: 900; */
  letter-spacing: 0.1em;
  line-height: 18px;
  padding: 12px 30px;
  margin: 0 10px 10px 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.2s;
  cursor: pointer;
  position: relative;
}
.bawah-call-to-action {
  margin-top: 30px;
}
.bawah-call-to-action-title {
  color: #ffffff;
  font-size: 14px;
  /* font-weight: 900; */
  letter-spacing: 0.1em;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}
.bawah-call-to-action-link-wrapper {
  margin-bottom: 0;
  margin-top: 10px;
  color: #ffffff;
  text-decoration: none;
}
.bawah-call-to-action-link-wrapper a {
  color: #ffffff;
  text-decoration: none;
}
.link-footer {
  color: white !important;
  font-weight: 300;
}
.hot-program-footer {
  background-color: #f52f63;
  padding: 3px;
  border-radius: 20px;
  font-weight: 300;
}

.bawah-social-links {
  bottom: 0;
  height: 54px;
  position: absolute;
  right: 0;
  width: 236px;
}

.bawah-social-amoeba-svg {
  height: 54px;
  left: 0;
  display: block;
  position: absolute;
  top: 0;
  width: 236px;
}

.bawah-social-amoeba-path {
  fill: #007a3f;
}

.bawah-social-link.linkedin {
  height: 26px;
  left: 3px;
  top: 11px;
  width: 26px;
}

.bawah-social-link {
  display: block;
  padding: 10px;
  position: absolute;
}

.hidden-link-text {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(0px 0px 99.9% 99.9%);
  clip-path: inset(0px 0px 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
  top: 50%;
}

.bawah-social-icon-svg {
  display: block;
}

.bawah-social-icon-path {
  fill: #fffff2;
  transition: fill 0.2s;
}

.bawah-social-link.twitter {
  height: 28px;
  left: 62px;
  top: 3px;
  width: 28px;
}

.bawah-social-link.youtube {
  height: 24px;
  left: 123px;
  top: 12px;
  width: 24px;
}

.bawah-social-link.github {
  height: 34px;
  left: 172px;
  top: 7px;
  width: 34px;
}

.bawah-copyright {
  background: #054599;
  color: #ffffff;
  /* padding: 15px 30px; */
  text-align: center;
  padding-bottom: 80px;
}

.parent-line {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 15%;
  padding-right: 15%;
}

.garis-end-2 {
  margin-top: 100px;
  border: 1px solid rgb(238, 238, 238);
  width: 100%;
}

.bawah-copyright-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.bawah-copyright-text {
  color: #fff;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
}

.bawah-copyright-link {
  color: #ffffff;
  text-decoration: none;
}

.p-address {
  font-weight: 200;
  color: white;
}

.all-sosmed {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
.img-sosmed {
  width: 90%;
}

@media (max-width: 768px) {
  /* .bawah {
          display: none;
      } */
}

/* Media Query For different screens */
@media (min-width: 320px) and (max-width: 479px) {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .bawah-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 800px;
    position: relative;
  }
}
@media (min-width: 480px) and (max-width: 599px) {
  /* smartphones, Android phones, landscape iPhone */
  .bawah-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 1050px;
    position: relative;
  }
}
@media (min-width: 600px) and (max-width: 800px) {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .bawah-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 1050px;
    position: relative;
  }
}
@media (min-width: 801px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

@media (min-width: 760px) {
  .bawah-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 450px;
    position: relative;
  }

  .bawah-wave-svg {
    height: 50px;
  }

  .bawah-content-column {
    width: 24.99%;
  }
}
@media (min-width: 568px) {
  /* .bawah-copyright {
    padding-bottom: 100px;
  } */
}
