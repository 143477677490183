body {
  background-color: #0c2569 !important;
}
.container-all {
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 70px;
}

/* RESPONSIVE */
@media only screen and (max-width: 500px) {
  .container-all {
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 2%;
  }
}
@media only screen and (max-width: 768px) {
  .container-all {
    padding-left: 2%;
    padding-right: 2%;
  }
}
@media only screen and (max-width: 820px) {
  .container-all {
    padding-left: 3%;
    padding-right: 3%;
  }
}
@media only screen and (max-width: 1024px) {
  .container-all {
    padding-left: 2%;
    padding-right: 2%;
  }
}