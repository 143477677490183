.bg-voucer {
  text-align: center;
  background-image: url("/src/assets/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /* background-size: 100%; */
  /* height: 300px; */
  padding: 40px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 40px;
  border-radius: 10px;
}
.title-vouvher {
  font-weight: 500;
  color: white;
  margin-bottom: 30px;
}
.btn-klaim-voucher {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  border: 1px solid transparent;
  padding: 2px;
  width: 100%;
  border-radius: 20px;
  gap: 5px;
  cursor: pointer;
}
.icon-klaim-voucher {
  width: 30px;
}
.btn-dapatkan-diskon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  border: 1px solid transparent;
  padding: 2px;
  width: 100%;
  border-radius: 20px;
  gap: 5px;
  cursor: pointer;
}
.icon-dapatkan-diskon {
  width: 25px;
}
.parent-btn {
  display: grid;
  place-items: center;
  gap: 10px;
}

/* RESPONSIVE */
@media only screen and (max-width: 500px) {
  .bg-voucer {
    margin-top: 15px;
  }
}
